
























































































































































































































































































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { get } from 'lodash'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: 'auto' }) width!: any
  @Prop({ default: 'space-between' }) footerJustify!: 'space-between' | 'start' | 'end'
  @Prop({ default: undefined }) horizontal: any | undefined
  @Prop({}) review!: PostStore
  createPostController = createPostController
  walletStore = walletStore

  redirectToPostDetail() {
    this.$router.push(`/community/post/${this.review.post.id}`)
  }

  get url() {
    if (this.review && this.review?.medias[0] && this.review?.medias[0].url) return this.review?.medias[0].url
    else return ''
  }

  get title() {
    return get(this.review, 'post.title')
  }

  get content() {
    return this.review.firstRawTextBlock
  }

  get lineClamp() {
    if (this.url && this.title) {
      return 2
    }

    if (!this.url && !this.title) {
      return 6
    }

    if (this.url && !this.title) {
      return 5
    }

    if (!this.url && this.title) {
      return 6
    }
  }
}
